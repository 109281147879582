import { motion } from "framer-motion";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";

import { theme } from "@/theme";

import Courses from "./Courses/Courses";
import Partners from "./Partners/Partners";
import Resources from "./Resources/Resources";

const MegaMenuStyle = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  height: auto;
  z-index: -1;
  background-color: ${theme.colors.gray[50]};
  padding-top: ${theme.spacing(10)};
  padding-bottom: ${theme.spacing(10)};
  border: 1px solid ${theme.colors.gray[200]};
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

interface MegaMenuInterface {
  currentNavigation?: string;
}

const MegaMenu = ({ currentNavigation }: MegaMenuInterface) => {
  return (
    <MegaMenuStyle
      initial="collapsed"
      animate="open"
      exit="collapsed"
      style={{ overflow: "hidden" }}
      variants={{
        open: { top: "100%" },
        collapsed: { top: "-666px" },
      }}
      transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      <Box
        display={"grid"}
        gridTemplateColumns="repeat(3, 100%)"
        style={{
          transition: "all 0.9s cubic-bezier(0.71, -0.02, 0.06, 1.15) 0s",
          transform: `translateX(${
            currentNavigation === "courses"
              ? "0%"
              : currentNavigation === "resources"
                ? "-100%"
                : currentNavigation === "partners"
                  ? "-200%"
                  : "0"
          })`,
        }}
      >
        <Container>
          <GridContainer spacing={theme.spacing(8)}>
            <Courses />
          </GridContainer>
        </Container>
        <Container>
          <GridContainer spacing={theme.spacing(8)}>
            <Resources />
          </GridContainer>
        </Container>
        <Container>
          <GridContainer spacing={theme.spacing(8)}>
            <Partners />
          </GridContainer>
        </Container>
      </Box>
    </MegaMenuStyle>
  );
};

export default MegaMenu;
