import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useGetNextCohortDateQuery } from "@/client/lmsApiClient";

import Badge from "@/design-system/Badge/badge";
import Box from "@/design-system/Box";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import { IconTypes } from "@/design-system/Icon/types";
import Typography from "@/design-system/Typography";
import GradientLine from "@/design-system/Utility/GradientLine";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { useAppSelector } from "@/hooks/hooks";

import { formatNextCohortDate } from "@/utils/utils";

import CoursesDynamicData from "@/content/courses/dynamic-data";

import { theme } from "@/theme";

interface styledCourseCardProps {
  hover: boolean;
  spanCol: number;
}

const CourseCard = styled.div<styledCourseCardProps>`
  grid-column: span ${({ spanCol }) => spanCol};
  transition: 0.3s;
  border-radius: 4px;
  .know-more {
    opacity: 0;
    transition: 0.3s;
  }

  .imgbox {
    transition: 0.3s;
    border-radius: 4px;
  }

  &:hover {
    background-color: ${(props) => (props.hover ? "white" : "")};
  }

  &:hover .imgbox {
    height: ${(props) => (props.hover ? "120px" : "")};
  }

  &:hover .know-more {
    opacity: ${(props) => (props.hover ? "1" : "0")};
  }
`;

const CardContent = styled.div`
  padding: ${theme.spacing(6)};
`;

const CourseInfoStyle = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 14px;
`;

const Gray100Box = styled.div`
  position: absolute;
  top: -${theme.spacing(10)};
  bottom: 0;
  right: 0;
  width: calc(100% + 2rem);
  left: 0;
  z-index: -1;
  height: calc(100% + ${theme.spacing(30)});
  background-color: ${theme.colors.gray[100]};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100% + 5rem);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: calc(100% + (100vw - 1200px) / 2);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: calc(100% + 10rem);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: calc(100% + (100vw - 1520px) / 2);
  }
`;

const BadgePosition = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Lists = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${theme.spacing(2)};
`;

const listEle = [
  {
    type: "Scaled - N - Location",
    text: "International Exposure",
  },
  {
    type: "Scaled - N - Gaming",
    text: "Gamified Experience",
  },
  {
    type: "Scaled - N - Chat",
    text: "Personalised Feedback",
  },
  {
    type: "Scaled - N - Teacher",
    text: "Personalised Skill Mapping",
  },
  {
    type: "Scaled - N - Video",
    text: "Interactive Live Sessions",
  },
];

const Courses = () => {
  const ref = useRef(null);
  const [dim, setDim] = useState({ w: 0, h: 0 });
  const dynamicData = CoursesDynamicData;
  const { data: nextCohortDate } = useGetNextCohortDateQuery();
  const { BIM_A, BIM_C, MCD } = nextCohortDate || {};
  const { BIM_A_US, BIM_C_US, MCD_US, INT_DES, INT_DES_US } = nextCohortDate || {};
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isNotIndia = Boolean(loginDetails?.userLocation !== "INDIA");
  const isRow = Boolean(loginDetails?.userLocation === "ROW");
  const isMena = Boolean(loginDetails?.userLocation === "MENA");

  useEffect(() => {
    if (ref?.current) {
      setDim({ w: ref?.current?.offsetWidth, h: ref?.current?.offsetHeight });
    }
  }, [ref?.current]);

  return (
    <>
      <Box gridColumn={isNotIndia ? "span 9" : "span 12"}>
        <GridContainer
          px={32}
          pt={theme.spacing(6)}
          alignItems="flex-start"
          spacing={theme.spacing(8)}
        >
          <CourseCard hover={true} spanCol={isNotIndia ? 4 : 3}>
            <Link className="link__" href={{ pathname: "/courses/building-information-modelling" }}>
              <Box height={140} overflow="hidden" className="imgbox">
                <ExportedImage
                  width={320}
                  height={140}
                  src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/bim.png`}
                  sizes="100vw"
                  style={{
                    width: "100%",
                    // transition: "0.3s",
                    objectFit: "cover",
                  }}
                  alt=""
                />{" "}
                <BadgePosition>
                  <Badge text="Most Popular" color={theme.colors.purple[600]} dotLeading={true} />
                </BadgePosition>
              </Box>
              <CardContent>
                <Typography
                  varient="subheadingl"
                  color={"primary.900"}
                  mb={theme.spacing(2)}
                  weightVarient="semibold"
                >
                  {isNotIndia
                    ? "PGP in Building Information Modelling (BIM) for Architects"
                    : "BIM Professional Course for Architects V2.0"}
                </Typography>
                <Typography varient="bodyxs" mb={theme.spacing(2)}>
                  Take your career to the next level by mastering the real-world application of BIM.
                </Typography>
                <CourseInfoStyle>
                  <GradientLine />
                  <div>
                    <div>
                      <Typography varient="captions">Next Cohort</Typography>{" "}
                      {isNotIndia ? (
                        <Typography varient="captions" weightVarient="semibold">
                          {BIM_A_US?.date || "Coming Soon"}
                        </Typography>
                      ) : (
                        <Typography varient="captions" weightVarient="semibold">
                          {isNotIndia ? BIM_A_US?.date : formatNextCohortDate(BIM_A?.date)}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <Typography varient="captions">Program Duration</Typography>
                      <Typography varient="captions" weightVarient="semibold">
                        {" "}
                        {isNotIndia
                          ? dynamicData.find((c) => c.courseId === "BIM_US")?.upcomingCohortDuration
                          : dynamicData.find((c) => c.courseId === "BIM")?.upcomingCohortDuration}
                      </Typography>
                    </div>
                  </div>
                </CourseInfoStyle>
                <Typography
                  varient="bodyxs"
                  mt={theme.spacing(5)}
                  className="know-more"
                  color={"primary.800"}
                  weightVarient="semibold"
                >
                  Know more
                </Typography>
              </CardContent>
            </Link>
          </CourseCard>
          <CourseCard hover={true} spanCol={isNotIndia ? 4 : 3}>
            <Link
              className="link__"
              href={{ pathname: `/courses/${isNotIndia ? "parametric" : "computational"}-design` }}
            >
              <Box height={140} overflow="hidden" className="imgbox">
                <ExportedImage
                  width={320}
                  height={140}
                  src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mcd.png`}
                  sizes="100vw"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt=""
                />{" "}
                <BadgePosition>
                  <Badge
                    text="Recently Launched"
                    dotLeading={true}
                    badgeStyle="dark"
                    colorVarient="blue"
                  />
                </BadgePosition>
              </Box>
              <CardContent>
                <Typography
                  varient="subheadingl"
                  color={"primary.900"}
                  mb={theme.spacing(2)}
                  weightVarient="semibold"
                >
                  {isNotIndia
                    ? "PGP in Parametric Design for Architects"
                    : "Master Computational Design Course"}
                </Typography>
                <Typography varient="bodyxs" mb={theme.spacing(2)}>
                  Break into an advanced career by learning to apply the power of computation to the
                  art of design.
                </Typography>
                <CourseInfoStyle>
                  <GradientLine />
                  <div>
                    <div>
                      <Typography varient="captions">Next Cohort</Typography>{" "}
                      <Typography varient="captions" weightVarient="semibold">
                        {isMena
                          ? "Coming soon"
                          : isNotIndia
                            ? MCD_US?.date || "Coming Soon"
                            : formatNextCohortDate(MCD?.date)}
                      </Typography>
                    </div>
                    <div>
                      <Typography varient="captions">Program Duration</Typography>
                      <Typography varient="captions" weightVarient="semibold">
                        {" "}
                        {isNotIndia
                          ? dynamicData.find((c) => c.courseId === "acm_us")?.upcomingCohortDuration
                          : dynamicData.find((c) => c.courseId === "acm")?.upcomingCohortDuration}
                      </Typography>
                    </div>
                  </div>
                </CourseInfoStyle>
                <Typography
                  varient="bodyxs"
                  mt={theme.spacing(5)}
                  className="know-more"
                  color={"primary.800"}
                  weightVarient="semibold"
                >
                  Know more
                </Typography>
              </CardContent>
            </Link>
          </CourseCard>
          <CourseCard hover={true} spanCol={isNotIndia ? 4 : 3}>
            <Link className="link__" href={{ pathname: "/courses/bim-for-civil-engineers" }}>
              <Box height={140} overflow="hidden" className="imgbox">
                <ExportedImage
                  width={320}
                  height={140}
                  src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/image_2.jpg`}
                  sizes="100vw"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt=""
                />{" "}
                <BadgePosition>
                  <Badge
                    text="New Arrival"
                    dotLeading={true}
                    badgeStyle="light"
                    colorVarient="warning"
                  />
                </BadgePosition>
              </Box>
              <CardContent>
                <Typography
                  varient="subheadingl"
                  color={"primary.900"}
                  mb={theme.spacing(2)}
                  weightVarient="semibold"
                >
                  {isNotIndia
                    ? "PGP in Building Information Modelling (BIM) for Civil Engineers"
                    : "BIM Professional Course For Civil Engineers"}
                </Typography>
                <Typography varient="bodyxs" mb={theme.spacing(2)}>
                  Unlock new civil engineering career levels by upskilling and building on
                  real-world projects from AEC industry.
                </Typography>
                <CourseInfoStyle>
                  <GradientLine />
                  <div>
                    <div>
                      <Typography varient="captions">Next Cohort</Typography>{" "}
                      <Typography varient="captions" weightVarient="semibold">
                        {isRow
                          ? "Coming soon"
                          : isNotIndia
                            ? BIM_C_US?.date || "Coming Soon"
                            : formatNextCohortDate(BIM_C?.date)}
                      </Typography>
                    </div>
                    <div>
                      <Typography varient="captions">Program Duration</Typography>{" "}
                      <Typography varient="captions" weightVarient="semibold">
                        {isNotIndia
                          ? dynamicData.find((c) => c.courseId === "BIM_C_US")
                            ?.upcomingCohortDuration
                          : dynamicData.find((c) => c.courseId === "BIM_C")?.upcomingCohortDuration}
                      </Typography>
                    </div>
                  </div>
                </CourseInfoStyle>

                <Typography
                  varient="bodyxs"
                  mt={theme.spacing(5)}
                  className="know-more"
                  color={"primary.800"}
                  weightVarient="semibold"
                >
                  Know more
                </Typography>
              </CardContent>
            </Link>
          </CourseCard>
          {!isNotIndia && (
            <CourseCard hover={true} spanCol={isNotIndia ? 4 : 3}>
              <Link
                className="link__"
                href={{ pathname: "/courses/interior-design-and-future-tech" }}
              >
                <Box height={140} overflow="hidden" className="imgbox">
                  <ExportedImage
                    width={320}
                    height={140}
                    src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/interior.png`}
                    sizes="100vw"
                    style={{
                      width: "100%",
                      // transition: "0.3s",
                      objectFit: "cover",
                    }}
                    alt=""
                  />{" "}
                  <BadgePosition>
                    <Badge
                      text="Newly launched"
                      dotLeading={true}
                      badgeStyle="light"
                      colorVarient="warning"
                    />
                  </BadgePosition>
                </Box>
                <CardContent>
                  <Typography
                    varient="subheadingl"
                    color={"primary.900"}
                    mb={theme.spacing(2)}
                    weightVarient="semibold"
                  >
                    Post Graduate Program in Interior Design & Future Tech
                  </Typography>
                  <Typography varient="bodyxs" mb={theme.spacing(2)}>
                    Shape Your Interior Design Career in future ready skills.
                  </Typography>
                  <CourseInfoStyle>
                    <GradientLine />
                    <div>
                      <div>
                        <Typography varient="captions">Next Cohort</Typography>{" "}
                        <Typography varient="captions" weightVarient="semibold">
                          {formatNextCohortDate(INT_DES?.date)}
                        </Typography>
                      </div>
                      <div>
                        <Typography varient="captions">Program Duration</Typography>
                        <Typography varient="captions" weightVarient="semibold">
                          {" "}
                          {isNotIndia
                            ? dynamicData.find((c) => c.courseId === "INT_DES_US")
                              ?.upcomingCohortDuration
                            : dynamicData.find((c) => c.courseId === "INT_DES")
                              ?.upcomingCohortDuration || "6 months"}
                        </Typography>
                      </div>
                    </div>
                  </CourseInfoStyle>
                  <Typography
                    varient="bodyxs"
                    mt={theme.spacing(5)}
                    className="know-more"
                    color={"primary.800"}
                    weightVarient="semibold"
                  >
                    Know more
                  </Typography>
                </CardContent>
              </Link>
            </CourseCard>
          )}
        </GridContainer>
        {!isNotIndia && (
          <div ref={ref} style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "40px",
                left: -dim.w,
                height: dim.h,
                width: dim.w,
                backgroundColor: theme.colors.purple[50],
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: "40px",
                left: dim.w,
                height: dim.h,
                width: dim.w,
                backgroundColor: theme.colors.purple[50],
              }}
            ></div>
            <FlexContainer
              mt={theme.spacing(10)}
              p={theme.spacing(6)}
              background={theme.colors.purple[50]}
              justifyContent={"space-around"}
              style={{ top: "40px" }}
            >
              {listEle.map((ele) => (
                <Lists key={ele.type}>
                  <Icon
                    color={theme.colors.purple[500]}
                    type={ele.type as IconTypes}
                    varient={"scaled"}
                    size={24}
                  />{" "}
                  <Typography varient="bodys" weightVarient="medium">
                    {ele.text}
                  </Typography>
                </Lists>
              ))}
            </FlexContainer>
          </div>
        )}
      </Box>

      {isNotIndia && (
        <Box gridColumn={"span 3"} py={theme.spacing(4)} px={theme.spacing(10)}>
          <Typography varient="bodyxs" color={"gray.600"}>
            Innovative Learning
          </Typography>
          <Typography
            varient="subheadingl"
            weightVarient="semibold"
            color={"purple.600"}
            mb={theme.spacing(15)}
          >
            <Box as="span" color={"grayBlue.600"}>
              An
            </Box>{" "}
            Unforgettable Experience
          </Typography>
          {listEle.map((ele) => (
            <Lists key={ele.type}>
              <Icon
                color={theme.colors.purple[700]}
                type={ele.type as IconTypes}
                varient={"scaled"}
                size={24}
              />{" "}
              <Typography varient="bodys" weightVarient="medium" mb={theme.spacing(4)}>
                {ele.text}
              </Typography>
            </Lists>
          ))}
          <Gray100Box />
        </Box>
      )}
    </>
  );
};

export default Courses;
