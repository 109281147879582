import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import { getBlogsData } from "@/client/OneistoxApiClient";

import PrimaryButton from "@/design-system/Button/PrimaryButton";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

import { DotStyle } from "./DotStyle";
import ReadInfo from "./ReadInfo";

const RightItemBox = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 40px;
`;

interface ImageCardProps {
  src?: string;
}

const ImageCardStyle = styled.div<ImageCardProps>`
  min-height: 356px;
  border-radius: 8px;
  padding: 48px 32px;
  background-image: linear-gradient(0deg, rgba(66, 48, 125, 0.7), rgba(66, 48, 125, 0.7)),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%),
    url(${({ src }) =>
    src ? src : `"${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/image_1.jpg"`});
  display: flex;
  background-size: 100%;
  flex-direction: column;
  justify-content: flex-end;
  color: ${theme.colors.base.white};
  box-shadow: ${theme.shadows.light.xs};
  width: 320px;
`;

export const BlogItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${theme.spacing(4)};
  padding: ${theme.spacing(2)};
`;

export async function getStaticProps() {
  const blogs = await getBlogsData();
  return { props: { blogsList: blogs.data } };
}

const editorsPickBlogs = [
  {
    isHubspot: true,
    url: "best-bim-courses-with-placements",
    img: "top_bim_courses_with_placements.jpg",
    readtime: "10  mins",
    categories__name: "BIM",
    subCategories__name: "News & Industry Insights",
    name: "6 Best BIM Courses With Placements (2022)",
    author__firstName: "Saumya",
    author__lastName: "Verma",
    author__img: "saumya_verma.jpg",
    createdAt: "2022-10-07T08:14:58.790Z",
    contentType: "Blog",
    tags: ["Editor's Pick"],
    description:
      "What are the top BIM Courses with placements? Which BIM online course should you choose? Read on to find out!",
  },
  {
    isHubspot: true,
    url: "computational-design-guide",
    img: "computational-design-guide.jpg",
    readtime: "13 mins",
    categories__name: "Computational Design",
    subCategories__name: "CD for Beginners",
    name: "Understanding Computational Design (The Ultimate Guide) - 2023",
    author__firstName: "Thet",
    author__lastName: "Hnin",
    author__img: "ThetHninSuAung.png",
    createdAt: "2022-12-16T08:14:58.790Z",
    contentType: "Blog",
    tags: [""],
    description:
      "Get started with your journey in Computational Design with this ultimate guide to understanding this advanced technology from A-Z",
  },
  {
    url: "career-in-bim",
    img: "BIM_Collaboration.jpg",
    readtime: "15 mins",
    categories__name: "BIM",
    subCategories__name: "Careers",
    name: "5 Things to Keep in Mind Before Starting Your Career in BIM",
    author__firstName: "Thet Hnin",
    author__lastName: "Su Aung",
    author__img: "ThetHninSuAung.png",
    createdAt: "2022-06-25T08:14:58.790Z",
    contentType: "Blog",
    tags: [""],
    description:
      "Starting a career in BIM is getting more popular among AEC professionals as the demand for BIM is growing. Here are 5 tips to know before starting a BIM career.",
  },
  {
    url: "bim-graduate-placements",
    img: "graduates_from_oneistox_bim_professional_course.jpg",
    readtime: "4.4 min",
    categories__name: "Company News",
    subCategories__name: "",
    name: "Graduates from Oneistox’s BIM Professional Course (Cohort - 1) Land Placements at Coveted AEC Firms",
    author__firstName: "Arushi ",
    author__lastName: "Mathur",
    author__img: "Arushi_Mathur.jpg",
    createdAt: "2022-09-19T08:14:58.790Z",
    contentType: "Blog",
    tags: [""],
    description:
      "Graduates from Oneistox’s first BIM cohort have been placed at MNCs like DAR, Techture, Colliers and TCE, at an average pay hike of 60%.",
  },
];

const Partners = () => {
  // const [editorsPickBlogs] = useState<any[]>(editorPickData);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     let blogs: any = props.blogsList || (await getBlogsData()).data || [];
  //     blogs = _.sortBy(blogs, "createdAt").reverse();
  //     blogs = blogs.filter((item: any) => item.tags.includes("Editor's Pick"));
  //     setEditorsPickBlogs(blogs);
  //   };
  //   fetchData();
  // }, []);

  return (
    <>
      {/* <LeftItemBox>
        <MenuItemStyle>
          <Icon
            color={theme.colors.purple[500]}
            type={IconType["menu-04"]}
            varient={IconVarient.solid}
          />
          <div>
            <Typography
              varient="bodyl"
              color={theme.colors.purple[900]}
              weightVarient="semibold"
            >
              Try our career path builder!
            </Typography>
            <Typography
              color={theme.colors.grayNeutral[600]}
              varient="captionl"
            >
              This short quiz will sort you out. Answer a few simple questions
              to get personal career advice and course recommendations.
            </Typography>
          </div>
        </MenuItemStyle>

        <MenuItemStyle>
          <Icon
            color={theme.colors.purple[500]}
            type={IconType["menu-04"]}
            varient={IconVarient.solid}
          />
          <div>
            <Typography
              varient="bodyl"
              color={theme.colors.purple[900]}
              weightVarient="semibold"
            >
              Events & Webinars
            </Typography>
            <Typography
              color={theme.colors.grayNeutral[600]}
              varient="captionl"
            >
              Lorem ipsum dolor sit amet consectetur. Felis arcu gravida viverra
              velit aenean auctor urna.
            </Typography>
          </div>
        </MenuItemStyle>
        <MenuItemStyle>
          <Icon
            color={theme.colors.purple[500]}
            type={IconType["menu-04"]}
            varient={IconVarient.solid}
          />
          <div>
            <Typography
              varient="bodyl"
              color={theme.colors.purple[900]}
              weightVarient="semibold"
            >
              FAQs & Support Center
            </Typography>
            <Typography
              color={theme.colors.grayNeutral[600]}
              varient="captionl"
            >
              Lorem ipsum dolor sit amet consectetur. Felis arcu gravida viverra
              velit aenean auctor urna.
            </Typography>
          </div>
        </MenuItemStyle>
      </LeftItemBox> */}

      <RightItemBox>
        {/* <ImageCardStyle> */}
        <Link
          href={`/blog/${editorsPickBlogs[0]?.url}`}
          target={editorsPickBlogs[0]?.isHubspot ? "_blank" : "_self"}
        >
          <ImageCardStyle
            src={`${process.env.NEXT_PUBLIC_CDN}/images/blog/blogHero/${editorsPickBlogs[0]?.img}`}
          >
            <FlexContainer alignItems={"center"} mb={theme.spacing(3)}>
              <Typography varient="labels" color={"inherit"}>
                FEATURED
              </Typography>
              <DotStyle />
              <Typography color={"inherit"} as={"span"} varient="labels" weightVarient="semibold">
                {editorsPickBlogs.length > 0 && editorsPickBlogs[0].categories__name.toUpperCase()}
              </Typography>
            </FlexContainer>

            <Typography
              mb={theme.spacing(3)}
              varient="heading6"
              color={"inherit"}
              weightVarient="semibold"
            >
              {editorsPickBlogs.length > 0 && editorsPickBlogs[0].name}
            </Typography>

            <ReadInfo color="inherit" blogData={editorsPickBlogs[0]} />
          </ImageCardStyle>
        </Link>

        <FlexContainer>
          <div>
            <div>
              <FlexContainer mr={"-40px"} alignItems={"center"} justifyContent="space-between">
                <Typography
                  color={theme.colors.gray[900]}
                  varient="bodyxs"
                  weightVarient="semibold"
                  p={2}
                  style={{
                    flexGrow: 1,
                    borderBottom: `1px solid ${theme.colors.primary[500]}`,
                  }}
                >
                  Editor's Picks
                </Typography>
                <a className="link__" href={"/learning-hub"}>
                  <PrimaryButton
                    size="sm"
                    label="View Blog"
                    color="tertiary"
                    suffixIcon={
                      <Icon
                        type={"arrow-narrow-up-right"}
                        color={theme.colors.purple[800]}
                        varient={"line"}
                      />
                    }
                  />
                </a>
              </FlexContainer>
            </div>
            {editorsPickBlogs.length > 3 &&
              editorsPickBlogs.slice(1, 4).map((data, index) => (
                <Link
                  href={`/blog/${data.url}`}
                  target={data?.isHubspot ? "_blank" : "_self"}
                  key={index}
                >
                  <BlogItem>
                    <ExportedImage
                      src={`${process.env.NEXT_PUBLIC_CDN}/images/blog/blogHero/${data?.img}`}
                      unoptimized
                      width="96"
                      height={"88"}
                      alt="Engineering"
                      objectFit="cover"
                      style={{ borderRadius: 8 }}
                    />

                    <div>
                      <Typography mb={theme.spacing(2)} varient="captions" color={"gray.500"}>
                        {data.categories__name}
                      </Typography>
                      <Typography
                        mb={theme.spacing(2)}
                        varient="bodys"
                        weightVarient="medium"
                        color={"gray.900"}
                      >
                        {data.name}
                      </Typography>
                      <ReadInfo blogData={data} />
                    </div>
                  </BlogItem>
                </Link>
              ))}
          </div>
        </FlexContainer>
      </RightItemBox>
    </>
  );
};

export default Partners;
