import { FC } from "react";

import { GrayStyledButton } from "./grayButton.styles";
import { GrayButtonProps } from "./types";

const GrayButton: FC<GrayButtonProps> = ({ variant, ...rest }) => {
  return <GrayStyledButton variant={variant} {...rest} />;
};

export default GrayButton;

GrayButton.defaultProps = {
  color: "default",
  variant: "filled",
};
