import dayjs from "dayjs";
import ExportedImage from "next-image-export-optimizer";
import { FC } from "react";

import FlexContainer from "@/design-system/FlexContainer";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

import { DotStyle } from "./DotStyle";

const ReadInfo: FC<{
  color?: string;
  blogData: {
    author__img: string;
    author__firstName: string;
    author__lastName: string;
    createdAt: string;
    readtime: string;
  };
}> = ({ color, blogData }) => {
  return (
    <FlexContainer alignItems={"center"}>
      <ExportedImage
        src={`${process.env.NEXT_PUBLIC_CDN}/images/blog/authors/${blogData?.author__img}`}
        width={24}
        height={24}
        unoptimized
        alt={`${blogData?.author__firstName}` + " " + `${blogData?.author__lastName}`}
        style={{
          borderRadius: "50%",
          width: 16,
          height: 16,
        }}
      />
      <Typography color={color || "inherit"} ml={theme.spacing(2)} varient="captions">
        {`${blogData?.author__firstName}` + " " + `${blogData?.author__lastName}`}
      </Typography>
      <DotStyle color={theme.colors.gray[600]} />
      <Typography color={color || "inherit"} varient="captions">
        {dayjs(blogData?.createdAt).format(`DD MMM'YY`)}
      </Typography>
      <DotStyle color={theme.colors.gray[600]} />
      <Typography color={color || "inherit"} varient="captions">
        {blogData?.readtime}
      </Typography>
    </FlexContainer>
  );
};

export default ReadInfo;
