import { FC } from "react";

import { StyledGradientLine } from "./GradientLine";
import { GradientLineInterface } from "./types";

const GradientLine: FC<GradientLineInterface> = ({ color, height }) => {
  return <StyledGradientLine color={color} height={height} />;
};

export default GradientLine;
