import styled, { css } from "styled-components";
import { space } from "styled-system";

import Button from "..";

import { GrayButtonProps } from "./types";

export const GrayStyledButton = styled(Button)<GrayButtonProps>`
  ${space}
  ${({ color }) =>
    color === "default" &&
    css`
      background-color: ${({ theme }) => theme.colors.base.white};
      color: ${({ theme }) => theme.colors.gray[800]};
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[50]};
        color: ${({ theme }) => theme.colors.gray[900]};
        box-shadow:
          0px 6px 4px rgba(0, 0, 0, 0.02),
          0px 3px 3px rgba(0, 0, 0, 0.03),
          0px 1px 2px rgba(0, 0, 0, 0.03),
          0px 0px 0px rgba(0, 0, 0, 0.03);
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.base.white};
        color: ${({ theme }) => theme.colors.gray[800]};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.gray[300]};
        border: 1px solid ${({ theme }) => theme.colors.gray[200]};
        background-color: ${({ theme }) => theme.colors.base.white};
      }
    `}

    ${({ color }) =>
    color === "secondary" &&
    css`
      color: ${({ theme }) => theme.colors.gray[100]};
      border: 1px solid ${({ theme }) => theme.colors.gray[100]};

      &:hover {
        color: ${({ theme }) => theme.colors.base.white};
      }
      &:active {
        color: ${({ theme }) => theme.colors.gray[100]};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.gray[300]};
      }
    `}

  ${({ color }) =>
    color === "tertiary" &&
    css`
      color: ${({ theme }) => theme.colors.gray[800]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[50]};
        color: ${({ theme }) => theme.colors.gray[900]};
      }
      &:active {
        background-color: unset;
        color: ${({ theme }) => theme.colors.gray[800]};
      }
      &:disabled {
        background-color: unset;
        color: ${({ theme }) => theme.colors.gray[300]};
      }
    `}

    ${({ variant }) =>
    variant === "text" &&
    css`
      background-color: unset;
      color: ${({ theme }) => theme.colors.gray[700]};
      padding: 0;
      height: auto;
      border: 0;

      &:hover {
        background-color: unset;
        color: ${({ theme }) => theme.colors.gray[900]};
      }
      &:active {
        background-color: unset;
        color: ${({ theme }) => theme.colors.gray[700]};
      }
      &:disabled {
        background-color: unset;
        color: ${({ theme }) => theme.colors.gray[300]};
        border: 0;
      }
    `}
    
    ${({ textColor }) =>
    textColor === "primary" &&
    css`
      background-color: ${({ theme }) => theme.colors.base.white};
      color: ${({ theme }) => theme.colors.primary[800]};
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[50]};
        color: ${({ theme }) => theme.colors.primary[800]};
        box-shadow:
          0px 6px 4px rgba(0, 0, 0, 0.02),
          0px 3px 3px rgba(0, 0, 0, 0.03),
          0px 1px 2px rgba(0, 0, 0, 0.03),
          0px 0px 0px rgba(0, 0, 0, 0.03);
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.base.white};
        color: ${({ theme }) => theme.colors.primary[800]};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.gray[300]};
        border: 1px solid ${({ theme }) => theme.colors.gray[200]};
        background-color: ${({ theme }) => theme.colors.base.white};
        opacity: 0.3;
      }
    `}

    ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
