import { motion } from "framer-motion";
import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import { forwardRef } from "react";
import styled, { css } from "styled-components";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Icon from "@/design-system/Icon";
import Typography from "@/design-system/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

const LeftNavStyle = styled.div`
  grid-column: span 10;
  display: flex;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: span 9;
  }
`;

const Menu = styled.ul<any>`
  display: none;
  height: 100%;
  list-style-type: none;
  align-items: center;
  & > button {
    margin-right: ${theme.spacing(6)};
    cursor: pointer;
    height: 100%;
    @media ((min-width: 1080px) and (max-width:1359px)) {
      margin-right: ${theme.spacing(4)};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    /* @media (min-width: "1166px") { */
    display: flex;
    position: "absolute";
  }
`;
const MenuContainer = styled(Box)`
  display: flex;
  height: 100%;
  align-items: center;
  ${({ active }: { active?: boolean }) =>
    active &&
    css`
      & svg {
        & path[stroke] {
          stroke: ${theme.colors.primary[700]};
        }
      }
    `}
  &:hover {
    & svg {
      & path[stroke] {
        stroke: ${theme.colors.primary[700]};
      }
    }
  }
`;
const MenuItem = styled(Typography)`
  ${({ active }: { active?: boolean }) =>
    active &&
    css`
      color: ${theme.colors.primary[700]};
    `}
  &:hover {
    color: ${theme.colors.primary[700]};
  }
`;
const IconBox = styled(motion.span)`
  height: 24px;
`;

// eslint-disable-next-line react/display-name
const LeftNav = forwardRef(({ handleOpenMegaMenu, currentNavigation, disableCta }: any, ref) => {
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isNotIndia = Boolean(loginDetails?.userLocation !== "INDIA");
  const isRow = Boolean(loginDetails?.userLocation === "ROW");

  return (
    <>
      <LeftNavStyle>
        {!disableCta && (
          <Link className="link__" href={"/"}>
            <Box
              position={{ _: "absolute", md: "inherit" }}
              top={{ _: "-10px", md: "inherit" }}
              mr={{ md: "24px" }}
            >
              <ExportedImage
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logo/logo_new.svg`}
                alt="Novatr"
                width={127}
                height={32}
              />
            </Box>
          </Link>
        )}
        {disableCta && (
          <Box>
            <Box
              position={{ _: "absolute", md: "inherit" }}
              top={{ _: "-10px", md: "inherit" }}
              mr={{ md: "24px" }}
            >
              <ExportedImage
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logo/logo_new.svg`}
                alt="Novatr"
                width={127}
                height={32}
              />
            </Box>
          </Box>
        )}
        {!disableCta && (
          <Menu ref={ref}>
            <UnstyledButton onClick={() => handleOpenMegaMenu("courses")}>
              <MenuContainer active={currentNavigation === "courses"}>
                <Box mr={2} as="span">
                  <MenuItem
                    color="gray.700"
                    varient={"bodys"}
                    weightVarient={"semibold"}
                    active={currentNavigation === "courses"}
                  >
                    {isNotIndia ? "Program" : "Courses"}
                  </MenuItem>
                </Box>
                <IconBox
                  transition={{ ease: "linear" }}
                  animate={{
                    rotate: currentNavigation === "courses" ? "180deg" : "0",
                  }}
                >
                  <Icon type={"chevron-down"} varient={"line"} size={24} />
                </IconBox>
              </MenuContainer>
            </UnstyledButton>
            <UnstyledButton onClick={() => handleOpenMegaMenu("resources")}>
              <MenuContainer active={currentNavigation === "resources"}>
                <Box mr={2} as="span">
                  <MenuItem
                    color="gray.700"
                    varient={"bodys"}
                    weightVarient={"semibold"}
                    active={currentNavigation === "resources"}
                  >
                    Resources
                  </MenuItem>
                </Box>

                <IconBox
                  transition={{ ease: "linear" }}
                  animate={{
                    rotate: currentNavigation === "resources" ? "180deg" : "0",
                  }}
                >
                  <Icon type={"chevron-down"} varient={"line"} size={24} />
                </IconBox>
              </MenuContainer>
            </UnstyledButton>
            {/* <UnstyledButton onClick={() => handleOpenMegaMenu("partners")}>
                <MenuContainer active={currentNavigation === "partners"}>
                  <Box mr={2} as="span">
                    <MenuItem
                      color="gray.700"
                      varient={"bodys"}
                      weightVarient={"semibold"}
                      active={currentNavigation === "partners"}
                    >
                      Partners
                    </MenuItem>
                  </Box>

                  <IconBox
                    transition={{ ease: "linear" }}
                    animate={{
                      rotate: currentNavigation === "partners" ? "180deg" : "0",
                    }}
                  >
                    <Icon
                      type={"chevron-down"}
                      varient={'line'}
                      size={24}
                    />
                  </IconBox>
                </MenuContainer>
              </UnstyledButton> */}
            {!isRow && (
              <UnstyledButton>
                <Link className="link__" href={"/hire-from-us"}>
                  <MenuContainer>
                    <Box mr={2} as="span" style={{ whiteSpace: "nowrap" }}>
                      <MenuItem
                        color="gray.700"
                        varient={"bodys"}
                        weightVarient={"semibold"}
                        active={currentNavigation === "partners"}
                      >
                        Hire From Us
                      </MenuItem>
                    </Box>
                  </MenuContainer>
                </Link>
              </UnstyledButton>
            )}
            <UnstyledButton>
              <Link className="link__" href={"/webinars"}>
                <MenuContainer>
                  <Box mr={2} as="span" style={{ whiteSpace: "nowrap" }}>
                    <MenuItem
                      color="gray.700"
                      varient={"bodys"}
                      weightVarient={"semibold"}
                      active={currentNavigation === "partners"}
                    >
                      Events
                    </MenuItem>
                  </Box>

                  {/* <IconBox
                      transition={{ ease: "linear" }}
                      animate={{
                        rotate:
                          currentNavigation === "partners" ? "180deg" : "0",
                      }}
                    >
                      <Icon
                        type={"chevron-down"}
                        varient={'line'}
                        size={24}
                      />
                    </IconBox> */}
                </MenuContainer>
              </Link>
            </UnstyledButton>
            <UnstyledButton>
              <Link className="link__" href={"/impact-on-aec"}>
                <MenuContainer>
                  <Box mr={2} as="span" style={{ whiteSpace: "nowrap" }}>
                    <MenuItem color="gray.700" varient={"bodys"} weightVarient={"semibold"}>
                      Our Impact
                    </MenuItem>
                  </Box>
                </MenuContainer>
              </Link>
            </UnstyledButton>
            {!isNotIndia && (
              <UnstyledButton>
                <Link
                  className="link__"
                  href={
                    "/career-quiz/questions/personal-details?utm_source=website_navbar&utm_medium=career_counselling"
                  }
                >
                  <MenuContainer>
                    <Box mr={2} as="span" style={{ whiteSpace: "nowrap" }}>
                      <MenuItem color="gray.700" varient={"bodys"} weightVarient={"semibold"}>
                        Career Counselling
                      </MenuItem>
                    </Box>
                  </MenuContainer>
                </Link>
              </UnstyledButton>
            )}
          </Menu>
        )}
      </LeftNavStyle>
    </>
  );
});

export default LeftNav;
