import SVG from "react-inlinesvg";
import styled from "styled-components";

import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const SocialInfoStyle = styled.div`
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  background-color: inherit;
`;

const SocialInfo = () => {
  return (
    <SocialInfoStyle>
      <Box mb={theme.spacing(4)}>
        <SVG
          height={20}
          width={100}
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logo_misc_4.svg`}
        />
      </Box>
      <Box>
        <Typography mb={16}>
          Join thousands of people who organise <br />
          work and life with Novatr.
        </Typography>
      </Box>
      <FlexContainer>
        <Box mr={"16px"}>
          <SVG src={`/icons/misc/instagram_footer.svg`} />
        </Box>
        <Box mr={"16px"}>
          <SVG src={`/icons/misc/facebook_footer.svg`} />
        </Box>
        <Box mr={"16px"}>
          <SVG src={`/icons/misc/linkedin_footer.svg`} />
        </Box>
        <Box>
          <SVG src={`/icons/misc/youtube_footer.svg`} />
        </Box>
      </FlexContainer>
    </SocialInfoStyle>
  );
};

export default SocialInfo;
