import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import styled from "styled-components";

import { loginFail, logout } from "@/actions/auth";

import Box from "@/design-system/Box";
import GrayButton from "@/design-system/Button/GrayButton";
import PrimaryButton from "@/design-system/Button/PrimaryButton";
import Icon from "@/design-system/Icon";
import Typography from "@/design-system/Typography";
import useMediaQuery from "@/design-system/useMediaQuery";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

const MyAcountStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowButtonStyle = styled.span`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  transition: transform 0.3s;
  align-items: center;
  justify-content: center;
`;

const MenuStyle = styled.ul`
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(6)};
  list-style-position: inside;
  & li:last-child {
    border: none;
  }
`;

const MenuItemStyle = styled(Typography)`
  &::marker {
    color: ${theme.colors.primary[300]};
  }
  padding: 12px 8px;
  border-bottom: 1px solid ${theme.colors.primary[100]};
`;

const LoginButton = styled.div`
  margin-right: ${theme.spacing(2)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 3;
    display: inline-flex;
  }
`;

const NavbarAcountInfo = ({
  displayProp,
  isWeb,
}: {
  displayProp: {
    _: string;
    md: string;
  };
  isWeb?: boolean;
}) => {
  const [isOpen, setIsopen] = useState(false);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const isMidScreen = useMediaQuery("(min-width:1080px) and (max-width:1359px)");

  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isIg = loginDetails?.userData?.roles?.includes("INDUSTRIAL_GUIDE");
  const isMentor = loginDetails?.userData?.roles?.includes("LEAD_MENTOR");

  return (
    <Box display={displayProp}>
      {loginDetails?.isAuthenticated ? (
        <div>
          <motion.div initial={false}>
            <MyAcountStyle>
              <PrimaryButton
                color="tertiary"
                onClick={() => setIsopen(!isOpen)}
                icon={
                  <Icon
                    size={24}
                    color={theme.colors.purple[800]}
                    type={"user-03"}
                    varient={"duotone"}
                  />
                }
                suffixIcon={
                  <>
                    {isMidScreen ? (
                      ""
                    ) : (
                      <ArrowButtonStyle
                        style={{
                          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                      >
                        <Icon
                          size={16}
                          color={theme.colors.primary[800]}
                          type={"chevron-down"}
                          varient={"line"}
                        />
                      </ArrowButtonStyle>
                    )}
                  </>
                }
                label={isMidScreen ? "" : loginDetails.userProfile.firstName}
              />
            </MyAcountStyle>
          </motion.div>

          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.div
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                className={isWeb ? "mobile-account-info" : ""}
                // style={{isMob ?( position: "absolute", top: "64px") : '' }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <Box
                  py={theme.spacing(4)}
                  px={theme.spacing(4)}
                  bg={"primary.25"}
                  borderRadius={theme.spacing(2)}
                  width={isWeb ? 200 : "auto"}
                >
                  <MenuStyle>
                    <Link className="link__" href={"/my-application"}>
                      <MenuItemStyle as={"li"}>My Applications</MenuItemStyle>
                    </Link>
                    {isMentor && (
                      <Link className="link__" href={"/mentor-hub"}>
                        <MenuItemStyle as={"li"}>Mentor Hub</MenuItemStyle>
                      </Link>
                    )}
                    {isIg && (
                      <Link className="link__" href={"/ig-hub"}>
                        <MenuItemStyle as={"li"}>IG Hub</MenuItemStyle>
                      </Link>
                    )}
                    <Link className="link__" href={"/learner-hub"}>
                      <MenuItemStyle as={"li"}>Learner Hub</MenuItemStyle>
                    </Link>
                    <Link className="link__" href={"/billing"}>
                      <MenuItemStyle as={"li"}>My Orders</MenuItemStyle>
                    </Link>
                    <Link className="link__" href={`/user-profile?userId=${loginDetails.userId}`}>
                      <MenuItemStyle as={"li"}>Profile</MenuItemStyle>
                    </Link>
                  </MenuStyle>

                  <GrayButton
                    onClick={() => dispatch(logout())}
                    icon={<Icon size={24} type={"user-03"} varient={"duotone"} />}
                    size="sm"
                    label="Log Out"
                  />
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <LoginButton>
          <Link
            id="ta_login_btn"
            className="link__"
            href={{ pathname: "/login" }}
            onClick={() => dispatch(loginFail())}
          >
            <PrimaryButton
              color="tertiary"
              icon={<Icon type={"user-03"} color={theme.colors.purple[800]} varient={"line"} />}
              label={isMidScreen ? "" : "Log In"}
              className={isMidScreen ? "" : "button__log_in"}
            />
          </Link>
        </LoginButton>
      )}
    </Box>
  );
};

export default NavbarAcountInfo;
